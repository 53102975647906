import React, { Component } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
//C:\Users\jai.1\Desktop\MBNextJs\ReactMaster\src\components\Cart\common\ApplyOffer\apply-offer.module.scss
//C:\Users\jai.1\Desktop\MBNextJs\ReactMaster\src\utility\templates\owlCarousel.js
import styles from 'components/Cart/common/ApplyOffer/apply-offer.module.scss'
import { convertToModule } from 'factory/utils/clientUtils';
// import 'swiper/css/effect-coverflow';
// import required modules
// import { EffectCoverflow } from 'swiper/modules';

// import "swiper/swiper.min.css";
import SwiperCore, {
    EffectCoverflow
} from 'swiper/core';

// install Swiper modules
// SwiperCore.use([Pagination]);
// SwiperCore.use([Navigation]);
// SwiperCore.use([Autoplay]);
SwiperCore.use([EffectCoverflow]);
class SwiperCoverFlow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            _owlID: 'owl' + Math.floor(Math.random() * 100000)
        }
    }

    componentDidMount() {

    }

    render() {
        const { items, margin, dots, className, nav, loop, slideBy = 1, reviewData, autoplay, autoplayTimeout, autoplayHoverPause, navSpeed, wrapperClass = "", initialSlide = 0, center = false, responsive = false, stopOnLastSlide, breakpoint, slidesPerViewProp, onSlideChange } = this.props;
        return (
            <div className={convertToModule(styles, `hk-swiper ${wrapperClass}`)}>
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={2} // or 'auto'
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    modules={[EffectCoverflow]}
                    className="mySwiper"
                    slideToClickedSlide={true}
                    loop={true}
                    speed={800}
                    parallax={true}
                    spaceBetween= {10}
                    onSlideChange={onSlideChange}
                >
                    {
                        this.props.children &&
                        this.props.children.map((ele, k) => {
                            return <SwiperSlide key={k}>{ele}</SwiperSlide>
                        })
                    }
                </Swiper>
            </div>
        )
    }
}
export default SwiperCoverFlow;