import React, { Component } from 'react';
import { convertToModule } from 'factory/utils/clientUtils';
import styles from "./VideosPageSection.module.scss";
import HK from 'factory/modules/HK';
import VideoCard from 'components/Assets/ToolBox/VideoCard';
import SwiperCoverFlow from './SwiperCoverFlow';
import OwlCarousel from 'utility/templates/owlCarousel';

const VIDEOS_DATA = [
    { video: HK.getImage('/video/updated/v1.mp4'), thumbnail: HK.getImage('/video/updated/v1_thumb.jpg') },
    { video: HK.getImage('/video/updated/v2.mp4'), thumbnail: HK.getImage('/video/updated/v2_thumb.jpg') },
    { video: HK.getImage('/video/updated/v3.mp4'), thumbnail: HK.getImage('/video/updated/v3_thumb.jpg') },
]
let data = [
    {
        img: 'https://scontent.cdninstagram.com/v/t51.29350-15/379698813_669944764847556_2141147298585851539_n.webp?stp=dst-jpg&_nc_cat=109&ccb=1-7&_nc_sid=c4dd86&_nc_ohc=ekvsXLcIOPIAX9ytXrW&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDho_0JMne_x5qyMSCxXOK904B7bDd5jIvSu7XJLyDjsw&oe=65327C46',
        caption: "Find out why Lara is switching to SuperMilk for her daughter. Swipe 👉...#Gritzo #SuperMilk #PersonalizedNutritionForYourChild",
        date: "September 19",
        link: "https://www.instagram.com/p/CxXXdYArPQh/?img_index=1"
    },
    {
        img: 'https://scontent.cdninstagram.com/v/t51.29350-15/375716498_287775144007974_1641484143093734990_n.webp?stp=dst-jpg&_nc_cat=108&ccb=1-7&_nc_sid=c4dd86&_nc_ohc=8nluAr-KbqcAX-c2cx4&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD4g1Yp9H3HEg9H0S6nwl1CvvHtYMuZSjm9VY_DjYE-Dw&oe=653283F4',
        caption: "Although nutrition-week has ended but continue your journey towards providing the right nutrition for your child. Here are 5 expert tips from the Gritzo team. Swipe 👉...#gritzo #supermilk #nutritionist #childnutrition #childnutritionist #kidsnutrition",
        date: "September 8",
        link: "https://www.instagram.com/p/Cw7IMcHrS5a/?img_index=1"
    },
    {
        img: 'https://scontent.cdninstagram.com/v/t51.29350-15/375129935_252225743857995_7170291029746872000_n.webp?stp=dst-jpg&_nc_cat=107&ccb=1-7&_nc_sid=c4dd86&_nc_ohc=wpXe2oChnQoAX8s2Y6T&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfB_ZyESKC6aFu-bmMf2WZSIZqR_iR17SAhd1Xda_ski_w&oe=6534F256',
        caption: "Teachers are the first ones to know that every child is unique and create personalized possibilities for children to grow and become the best version of themselves. Wishing all teachers, gurus, masters and guides, a Happy Teacher's Day!.      .     .      #happyteachersday #teachersday #gritzo #supermilk",
        date: "September 5",
        link: "https://www.instagram.com/p/Cwzb2dHLFSj/"
    },
    {
        img: 'https://scontent.cdninstagram.com/v/t51.29350-15/368153483_1758722527879403_159676659888088968_n.webp?stp=dst-jpg&_nc_cat=103&ccb=1-7&_nc_sid=c4dd86&_nc_ohc=Dw71eLtkuMgAX855Vbx&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBu8fAIMe56UkdQogChJ6Cl9wNsyd_DNARgztm6dfkzBA&oe=6531C92B',
        caption: "If you are still giving your child malt-based health drinks, this is for you! Swipe 👉 .      .      .      #Gritzo #Supermilk #Gritzo #KidsNutrition #Personalized #malt #maltdrinks  #kidshealth",
        date: "August 19",
        link: "https://www.instagram.com/p/CwHutuDL0cp/?img_index=1"
    }
]

class VideosPageSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            play: false,
            selectedIndex: null
        }
    }

    handlePause = () => {
        if (typeof window !== undefined) {
            const videoEl = document.querySelectorAll(".video-ele");
            {
                for (const video of videoEl)
                    if (video) {
                        video.pause();
                    }
            }
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.videoVisible);
    }
    componentDidUpdate() {
        window.addEventListener('scroll', this.videoVisible);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.videoVisible);
    }
    videoVisible = () => {
        var element = document.querySelector(".videos-ps-component");
        if (element) {
            var rect = element.getBoundingClientRect();
            var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            if ((rect.bottom < 0 || rect.top - viewHeight >= 0)) {
                this.handlePause();
            }
        }
    };
    render() {
        return (
            <div className={convertToModule(styles, "videos-ps-component")}>
                <div className={convertToModule(styles, "videos-ps-inner HK-pagecontainer")}>
                    <div className={convertToModule(styles, "BS-products")}>
                        {!HK.isMobile() ?
                            <SwiperCoverFlow
                                className={"BS-Desk goal-section-container"}
                                margin={0}
                                items={HK.config.slider.getSlidesNo(VIDEOS_DATA, 2, 3)}
                                slideBy={2}
                                dots={false}
                                nav={true}
                                responsive={false} //todoS same breakpoints
                                onSlideChange={this.handlePause}
                            >
                                {
                                    VIDEOS_DATA && VIDEOS_DATA.length > 0 && VIDEOS_DATA.map((ele, k) => (
                                        <VideoCard
                                            key={k}
                                            vData={ele}
                                        />
                                    ))
                                }
                            </SwiperCoverFlow>
                            :
                            <OwlCarousel
                                className={"BS-Desk video-section-container"}
                                margin={0}
                                items={1}
                                slideBy={1}
                                dots={true}
                                autoplayTimeout={3500}
                                loop={true}
                                onSlideChange={this.handlePause}
                            >
                                {
                                    VIDEOS_DATA && VIDEOS_DATA.length > 0 && VIDEOS_DATA.map((ele, k) => (
                                        <VideoCard
                                            key={k}
                                            vData={ele}
                                        />
                                    ))
                                }
                            </OwlCarousel>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default VideosPageSection;